import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://cwamerchantservices.com/wp-content/uploads/2015/05/EMV-PICTURE.png"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "300px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAARlAAAEZQAGA43XUAAADv0lEQVQ4yyXS209TdwDA8fMH7GW8mCxxS3RqlNKec37n9EKhxVGucrEochMLmo6WXiiltFCEqMg1cxMGOhWpSlsuLTPoNsbUJTObD3tgMTFZtpgtW5Yse932/l3C/oFvPg9fqbjvFmZrKUa9lMBHOZYef8eN/DNa2j0UtY9hi2fRI8vovYsIuwtx3I3Q7QhnA6J3ESV4B6PNhcEdoTCcQbK19FFokOkKj5DJ5Cl1lFFZ7iKzukmrd4CDqgu9pAJhdiAsTlSjjGiOog2k0aIriIE0im8Ro9mJoW0UyeKo4oi9jsnsc8JT9ymovcgbdh9F1hpyqTn8/X6MHeNoF6YRoduoZQ2oJ3vR4huISAqtL4UYyKJ0Te5FJU1RMblDVPffJv3oW2pj1xmfn+XxkpdnS+d49WkPgdmPORrKosfXULunMClmVN88IppG9N9Hi2URQw+R671IQlaxdY3xdtt18ukZft7p4/ev/PDHFb5eaCI/UcPTTBBL/12U0PKeRi4/jew6g4jnEeFl1I5RlONuZM2OpCoa1gsTHGydYv6Sm393E+xuhXi50c29iJVXj/z89bSH2PQkR3pX0BI5lJ45ZL0EuboT2ezAKOsY32tGeMaRVFnFen6cQx2zlDsV/nwR56fHPlJhndxsI7886ePHLS/fbw1gC8xjOh1DcdQiG2Vkk4KpMYAevElpMocxkkZSTTJ66yB6LEvBUSebC628XPUwc9bAPz+M8PrLIK+3A/y24+eEq5hjBoFc0YrcPkqRVkJl7wwX5ra5uvw5rmT2f6Go68aWWGV/XYK6ap2/d0d4caeZX5+Eef7Aw53xRtxN1ZgaQ8i+BRzDa3Rc36HFl+CEu5WWyTztkxvUjmaRREklwl6BOZZGC9xi3wEjF71WvsmeJ+p1UXhoP/sOWzh6foHieAY58gBLNEV47iHJpW0CwQjyqQjyYA5TJIOkuX2oikBUd6DZyig8/C4Fbx3gzaIq3mlIYj4VwnO2E89klviNLU5eWedYYJmqkTTO5DqGpiiKpQzhvYbankQy93yIqppRVR1R3Y7onsARW6Z8ZI3asVWii1tUus8xfGmahsvrVIyu7r2nRFIog2vo/nmEVoyq6CiKGcmSWEdUNO8FzcEbaEOblMZX6Lm2SdvEGolbX1AzfI+6+iaUyjaUgQx69AF6fB2t+yqqsKEKK0LYEN1TSJbhHOb3P0CVFUTlGayDaQyhFOdm8sQ/+Yzjw2nsyQ1MnZcRJhntpB99KIfmuYJqUhDOerTgTURZA6K2i/8AnDFfdKYMVRIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "EMV PICTURE",
            "title": "EMV PICTURE",
            "src": "/static/e256ed6f19d79c15b2a9af0a96d0ae9c/fb9bc/EMV-PICTURE-300x210.png",
            "srcSet": ["/static/e256ed6f19d79c15b2a9af0a96d0ae9c/53d97/EMV-PICTURE-300x210.png 100w", "/static/e256ed6f19d79c15b2a9af0a96d0ae9c/bbdec/EMV-PICTURE-300x210.png 200w", "/static/e256ed6f19d79c15b2a9af0a96d0ae9c/fb9bc/EMV-PICTURE-300x210.png 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <h4>{`EMV Shift : Real Life "Mad Men" Take on EMV Marketing`}</h4>
    <p>{`One of the more difficult challenges of the U.S. EMV conversion is `}<a parentName="p" {...{
        "href": "http://www.paymentssource.com/news/regulation-compliance/a-third-of-us-merchants-have-never-heard-of-emv-report-3020533-1.html"
      }}>{`getting the word out`}</a>{`, especially given the urgency of the October deadline the card networks set for a fraud liability shift.`}</p>
    <p>{`Some companies are starting to treat this marketing challenge as an opportunity, making their advertising more about the shift to EMV security than about their own products. ShopKeep, a mobile point of sale provider, is running an advertising campaign that cuts straight to the chase.`}</p>
    <p>{`One ad states bluntly: "The card companies will hold you liable for fraud if you don't have EMV technology — liable for their cards. That's going to make you mad."`}</p>
    <p>{`Partners and Napier New York produced the ShopKeep ad campaign, which features two television spots and other digital content. One of the TV spots is a branding message around ShopKeep tied to the need to migrate to EMV, the other general messaging around EMV, liability and ShopKeep's role.`}</p>
    <p>{`"The liability shift is coming, and among our target market of small to medium sized businesses there's not a lot of awareness," said Brian Zang, senior vice president of sales and marketing at ShopKeep. "They mostly have their heads down running their businesses."`}</p>
    <p>{`"It starts with consumers. Consumers don't necessarily know what EMV is," Zang said. "If you've traveled that means 'OK, my card has a chip on it now.' But I don't think that people understand what it means for the larger payment industry."`}</p>
    <p>{`CardWorks Acquiring – Merchant Services continues to work with our industry partners to provide merchants with affordable, easy-to-implement EMV solutions with the industry’s best security capabilities. To ensure your equipment is EMV compliant, contact CWA Merchant Support today at (866) 210-4625 X1 or via email at `}<a parentName="p" {...{
        "href": "mailto:merchantsupport@cwams.com"
      }}>{`merchantsupport@cwams.com`}</a>{`.`}</p>
    <p>{`To read the rest of this story, visit `}<a parentName="p" {...{
        "href": "http://www.paymentssource.com/news/technology/real-life-mad-men-take-on-emv-marketing-3021423-1.html?utm_campaign=atm%20debit-may%2028%202015&utm_medium=email&utm_source=newsletter&ET=paymentssource%3Ae4458611%3A4753333a%3A&st=email"
      }}>{`PAYMENT SOURCE.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      